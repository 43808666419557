@import "ngx-toastr/toastr";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

body {
  overflow: visible;
  margin: 0;
  padding-bottom: 0;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb:vertical {
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(0, 123, 255, 1) 0%,
    rgba(28, 166, 218, 1) 50%,
    rgba(102, 178, 255, 1) 100%
  );
}

::-webkit-scrollbar-track:vertical {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.ngx-file-drop__drop-zone {
  margin-top: 8px !important;
}

.back {
  align-self: flex-start !important;
  margin-left: 40px;
}
